<template>
  <div id="manual-lo-create">
    <v-overlay :value="ProgressLoadingFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>{{ PageDescription }}</h4>
      </v-card-subtitle>

      <v-card-text>
        <h6>* indicates required field</h6>
        <v-row wrap>
          <v-col align="center" cols="12" md="12">
            <v-btn
              large
              @click.prevent="backPage"
              color="#EDBE38"
              elevation="30"
              shaped
              class="font-size-h6 fsize-3 mr-3 my-3 white--text"
            >
              <v-icon dark>mdi-arrow-left-circle</v-icon>&nbsp; Back
            </v-btn>
            <v-btn
              large
              @click.prevent="refreshPageData"
              color="#EDBE38"
              elevation="30"
              shaped
              class="font-size-h6 mr-3 my-3 white--text"
            >
              <v-icon dark>mdi-refresh</v-icon>&nbsp; Refresh
            </v-btn>
          </v-col>
        </v-row>
        <br />
        <br />
        <v-container v-if="StartupLoadingFlag">
          <v-row wrap>
            <v-col cols="12" md="3" v-for="n in 16" :key="n">
              <v-skeleton-loader
                v-bind="attrs"
                type="paragraph"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-container>
        <v-container v-if="!StartupLoadingFlag">
          <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
            <b-spinner
              variant="primary"
              type="grow"
              label="Loading..."
            ></b-spinner>
          </div>
          <v-form ref="form2" v-model="valid1" lazy-validation>
            <v-card elevation="3" outlined>
              <v-card-title>
                <span>Sponsor LO details &nbsp;</span>
              </v-card-title>
              <v-container>
                <v-row wrap>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label>
                      <h6><span class="text-danger">*</span> Sponsored by</h6>
                    </label>
                    <v-select
                      :reduce="(option) => option.value"
                      :items="SponsorTypeOptions"
                      :rules="SponsorTypeRules"
                      v-model="SponsorType"
                      required
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3" v-if="SponsorType == 2">
                    <label>
                      <h6 class="mycard-text1 fsize-4">
                        <span class="text-danger">*</span> Membership ID for
                        Sponsor
                      </h6>
                    </label>
                    <v-text-field
                      v-model="SponsorMembershipId"
                      :rules="SponsorMembershipIdRules"
                      placeholder="Membership ID"
                      hint="Membership id for sponsor member"
                      append-outer-icon="mdi-magnify-plus"
                      :loading="SponsorMembershipIdLoading"
                      @click:append-outer="getMemberDetails(1)"
                      persistent-hint
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3" v-if="SponsorType == 2">
                    <label>
                      <h6 class="mycard-text1 fsize-4">Sponsor Photo</h6>
                    </label>
                    <br />
                    <img
                      :src="SponsorMemberData.MemberImage"
                      width="100"
                      v-if="SponsorMemberData.Fullname != ''"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3" v-if="SponsorType == 2">
                    <label>
                      <h6 class="mycard-text1 fsize-4">Sponsor Details</h6>
                    </label>
                    <br />
                    <h5>{{ SponsorMemberData.Fullname }}</h5>
                    <h5>{{ SponsorMemberData.MemberDesignation }}</h5>
                  </v-col>
                  <v-col cols="12" sm="6" md="3" v-if="SponsorType == 2">
                    <label>
                      <h6>
                        <span class="text-danger">*</span> Sponsor Mobile Number
                      </h6>
                    </label>
                    <v-text-field
                      v-model="SponsorMemberData.MobileNo"
                      :rules="SponsorMobileNoRules"
                      :counter="10"
                      v-mask="'##########'"
                      type="number"
                      hint="Enter sponsor mobile number"
                      persistent-hint
                      dense
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="3" v-if="SponsorType == 2">
                    <label>
                      <h6>
                        <span class="text-danger">*</span> Sponsor Email Id
                      </h6>
                    </label>
                    <v-text-field
                      v-model="SponsorMemberData.EmailId"
                      :rules="SponsorEmailIdRules"
                      type="email"
                      hint="Enter sponsor email id"
                      persistent-hint
                      dense
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="3" v-if="SponsorType == 1">
                    <label>
                      <h6><span class="text-danger">*</span> Sponsor Zone</h6>
                    </label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :items="SponsorZoneCodeOptions"
                      :rules="SponsorZoneCodeRules"
                      :loading="SponsorZoneCodeOptionsLoading"
                      v-model="SponsorZoneCode"
                      hint="Select Sponsor Zone"
                      dense
                      clearable
                      required
                      outlined
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="3" v-if="ZonePresidentFlag">
                    <label>
                      <h6>
                        <span class="text-danger">*</span> Zone President
                        Details
                      </h6>
                    </label>
                    <v-img
                      :src="ZonePresident.PresidentImagePath"
                      :alt="ZonePresident.PresidentFullName"
                      lazy-src="/no-image-available.png"
                      max-width="100"
                      max-height="100"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                    <br />
                    <h4>{{ ZonePresident.PresidentFullName }}</h4>
                    <h4>{{ ZonePresident.PresidentMembershipId }}</h4>
                  </v-col>
                  <v-col cols="12" sm="6" md="3" v-if="ZonePresidentFlag">
                    <label>
                      <h6>
                        <span class="text-danger">*</span> Zone President Mobile
                        Number
                      </h6>
                    </label>
                    <h4>{{ ZonePresident.PresidentMobileNo }}</h4>
                  </v-col>
                  <v-col cols="12" sm="6" md="3" v-if="ZonePresidentFlag">
                    <label>
                      <h6>
                        <span class="text-danger">*</span> Zone President Email
                        Id
                      </h6>
                    </label>
                    <h4>{{ ZonePresident.PresidentEmailid }}</h4>
                  </v-col>
                  <v-col cols="12" sm="6" md="3" v-if="ZonePresidentFlag">
                    <label>
                      <h6><span class="text-danger">*</span> Sponsor LO</h6>
                    </label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :items="SponsorLomCodeOptions"
                      :rules="SponsorLomCodeRules"
                      :loading="SponsorLomCodeOptionsLoading"
                      v-model="SponsorLomCode"
                      hint="Select Sponsor LO"
                      dense
                      clearable
                      required
                      outlined
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="3" v-if="PresidentFlag">
                    <label>
                      <h6>
                        <span class="text-danger">*</span> LO President Details
                      </h6>
                    </label>
                    <v-img
                      :src="President.MemberImagePath"
                      :alt="President.MemberName"
                      lazy-src="/no-image-available.png"
                      max-width="100"
                      max-height="100"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                    <br />
                    <h4>{{ President.MemberName }}</h4>
                    <h4>{{ President.MembershipId }}</h4>
                  </v-col>
                  <v-col cols="12" sm="6" md="3" v-if="PresidentFlag">
                    <label>
                      <h6>
                        <span class="text-danger">*</span> LO President Mobile
                        Number
                      </h6>
                    </label>
                    <v-text-field
                      v-model="President.PrimaryMobile"
                      :rules="MobileNoRules"
                      :counter="10"
                      v-mask="'##########'"
                      type="number"
                      hint="Enter LO president mobile number"
                      persistent-hint
                      dense
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="3" v-if="PresidentFlag">
                    <label>
                      <h6>
                        <span class="text-danger">*</span> LO President Email Id
                      </h6>
                    </label>
                    <v-text-field
                      v-model="President.EmailId"
                      :rules="EmailIdRules"
                      type="email"
                      hint="Enter LO president email id"
                      persistent-hint
                      dense
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>

            <br />
            <br />

            <v-card elevation="3" outlined>
              <v-card-title>
                <span>New LO Title Details &nbsp;</span>
              </v-card-title>
              <v-container>
                <v-row wrap>
                  <v-col cols="12" sm="6" md="3">
                    <label>
                      <h6><span class="text-danger">*</span> State</h6>
                    </label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :loading="StateCodeOptionsLoading"
                      :items="StateCodeOptions"
                      :rules="StateCodeRules"
                      v-model="StateCode"
                      required
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <label>
                      <h6><span class="text-danger">*</span> District</h6>
                    </label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :loading="DistrictCodeOptionsLoading"
                      :items="DistrictCodeOptions"
                      :rules="DistrictCodeRules"
                      v-model="DistrictCode"
                      required
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <label>
                      <h6><span class="text-danger">*</span> City</h6>
                    </label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :loading="CityCodeOptionsLoading"
                      :items="CityCodeOptions"
                      :rules="CityCodeRules"
                      v-model="CityCode"
                      dense
                      clearable
                      required
                      outlined
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <label>
                      <h6><span class="text-danger">*</span> New LO name</h6>
                    </label>
                    <v-text-field
                      v-model="LomName"
                      :rules="LomNameRules"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <h6><span class="text-danger">*</span> LGB Resolution</h6>
                    <v-file-input
                      :clearable="false"
                      truncate-length="25"
                      accept="application/pdf"
                      placeholder="Upload pdf"
                      v-model="LGBResolution"
                      prepend-icon="mdi-file-pdf"
                    >
                      <template v-slot:append-outer>
                        <v-icon color="red" @click="LGBResolution = {}"
                          >mdi-delete</v-icon
                        >
                      </template>
                    </v-file-input>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <h6>
                      <span class="text-danger">*</span> Orientation Certificate
                    </h6>
                    <v-file-input
                      :clearable="false"
                      truncate-length="25"
                      accept="application/pdf"
                      placeholder="Upload pdf"
                      v-model="OrientationCertificate"
                      prepend-icon="mdi-file-pdf"
                    >
                      <template v-slot:append-outer>
                        <v-icon color="red" @click="OrientationCertificate = {}"
                          >mdi-delete</v-icon
                        >
                      </template>
                    </v-file-input>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <h6>
                      <span class="text-danger">*</span> Signed Membership List
                    </h6>
                    <v-file-input
                      :clearable="false"
                      truncate-length="25"
                      accept="application/pdf"
                      placeholder="Upload pdf"
                      v-model="MembershipList"
                      prepend-icon="mdi-file-pdf"
                    >
                      <template v-slot:append-outer>
                        <v-icon color="red" @click="MembershipList = {}"
                          >mdi-delete</v-icon
                        >
                      </template>
                    </v-file-input>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>

            <br />
            <br />

            <v-card elevation="3" outlined>
              <v-card-title>
                <span>New LO President Details &nbsp;</span>
              </v-card-title>
              <v-container>
                <v-row wrap>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label>
                      <h6>
                        <span class="text-danger">*</span> New LO President Type
                      </h6>
                    </label>
                    <v-select
                      :reduce="(option) => option.value"
                      :items="NewLoPresidentTypeOptions"
                      :rules="NewLoPresidentTypeRules"
                      v-model="NewLoPresidentType"
                      required
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    lg="3"
                    md="3"
                    v-if="NewLoPresidentType == 2"
                  >
                    <label>
                      <h6 class="mycard-text1 fsize-4">
                        <span class="text-danger">*</span> Membership ID for LO
                        President
                      </h6>
                    </label>
                    <v-text-field
                      v-model="NewLoPresidentMembershipId"
                      :rules="NewLoPresidentMembershipIdRules"
                      placeholder="Membership ID"
                      hint="Membership id for sponsor member"
                      append-outer-icon="mdi-magnify-plus"
                      :loading="NewLoPresidentMembershipIdLoading"
                      @click:append-outer="getMemberDetails(2)"
                      persistent-hint
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    lg="3"
                    md="3"
                    v-if="NewLoPresidentType == 2"
                  >
                    <label>
                      <h6 class="mycard-text1 fsize-4">President Photo</h6>
                    </label>
                    <br />
                    <img
                      :src="NewLoPresidentData.MemberImage"
                      width="100"
                      v-if="NewLoPresidentData.Fullname != ''"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    lg="3"
                    md="3"
                    v-if="NewLoPresidentType == 2"
                  >
                    <label>
                      <h6 class="mycard-text1 fsize-4">President Details</h6>
                    </label>
                    <br />
                    <h5>{{ NewLoPresidentData.Fullname }}</h5>
                    <h5>{{ NewLoPresidentData.MemberDesignation }}</h5>
                  </v-col>
                  <v-col cols="12" sm="6" md="3" v-if="NewLoPresidentType == 2">
                    <label>
                      <h6>
                        <span class="text-danger">*</span> President Mobile
                        Number
                      </h6>
                    </label>
                    <v-text-field
                      v-model="NewLoPresidentData.MobileNo"
                      :rules="PresidentMobileNoRules"
                      :counter="10"
                      v-mask="'##########'"
                      type="number"
                      hint="Enter president mobile number"
                      persistent-hint
                      dense
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="3" v-if="NewLoPresidentType == 2">
                    <label>
                      <h6>
                        <span class="text-danger">*</span> President Email Id
                      </h6>
                    </label>
                    <v-text-field
                      v-model="NewLoPresidentData.EmailId"
                      :rules="PresidentEmailIdRules"
                      type="email"
                      hint="Enter president email id"
                      persistent-hint
                      dense
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    lg="3"
                    md="3"
                    v-if="NewLoPresidentFlag"
                  >
                    <label>
                      <h6><span class="text-danger">*</span> First Name</h6>
                    </label>
                    <v-text-field
                      v-model="FirstName"
                      :rules="FirstNameRules"
                      hint="Enter president first name"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    lg="3"
                    md="3"
                    v-if="NewLoPresidentFlag"
                  >
                    <label>
                      <h6>Middle Name</h6>
                    </label>
                    <v-text-field
                      v-model="MiddleName"
                      :rules="MiddleNameRules"
                      hint="Enter president middle name"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    lg="3"
                    md="3"
                    v-if="NewLoPresidentFlag"
                  >
                    <label>
                      <h6>Last Name</h6>
                    </label>
                    <v-text-field
                      v-model="LastName"
                      :rules="LastNameRules"
                      hint="Enter president last name"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" v-if="NewLoPresidentFlag">
                    <label>
                      <h6><span class="text-danger">*</span> Street 1</h6>
                    </label>
                    <v-text-field
                      v-model="Street1"
                      :rules="Street1Rules"
                      hint="Enter president street line 1"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="8" v-if="NewLoPresidentFlag">
                    <label>
                      <h6>Street 2</h6>
                    </label>
                    <v-text-field
                      v-model="Street2"
                      :rules="Street2Rules"
                      hint="Enter president street line 2"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" v-if="NewLoPresidentFlag">
                    <label>
                      <h6>Area</h6>
                    </label>
                    <v-text-field
                      v-model="AreaName"
                      :rules="AreaNameRules"
                      hint="Enter president area"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" v-if="NewLoPresidentFlag">
                    <label>
                      <h6><span class="text-danger">*</span> State</h6>
                    </label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :loading="PresidentStateCodeOptionsLoading"
                      :items="PresidentStateCodeOptions"
                      :rules="PresidentStateCodeRules"
                      v-model="PresidentStateCode"
                      required
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" v-if="NewLoPresidentFlag">
                    <label>
                      <h6><span class="text-danger">*</span> District</h6>
                    </label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :loading="PresidentDistrictCodeOptionsLoading"
                      :items="PresidentDistrictCodeOptions"
                      :rules="PresidentDistrictCodeRules"
                      v-model="PresidentDistrictCode"
                      required
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" v-if="NewLoPresidentFlag">
                    <label>
                      <h6><span class="text-danger">*</span> City</h6>
                    </label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :loading="PresidentCityCodeOptionsLoading"
                      :items="PresidentCityCodeOptions"
                      :rules="PresidentCityCodeRules"
                      v-model="PresidentCityCode"
                      dense
                      clearable
                      required
                      outlined
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" v-if="NewLoPresidentFlag">
                    <label>
                      <h6><span class="text-danger">*</span> Pincode</h6>
                    </label>
                    <v-text-field
                      v-model="Pincode"
                      :rules="PincodeRules"
                      hint="Enter president pincode"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" v-if="NewLoPresidentFlag">
                    <label>
                      <h6><span class="text-danger">*</span> Mobile No</h6>
                    </label>
                    <v-text-field
                      v-model="PresidentMobileNo"
                      :rules="PresidentMobileNoRules"
                      :counter="10"
                      v-mask="'##########'"
                      type="number"
                      hint="Enter president mobile number"
                      dense
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" v-if="NewLoPresidentFlag">
                    <label>
                      <h6><span class="text-danger">*</span> Email ID</h6>
                    </label>
                    <v-text-field
                      v-model="PresidentEmailId"
                      :rules="PresidentEmailIdRules"
                      hint="Enter president email id"
                      type="email"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>

            <br />
            <br />

            <v-card elevation="3" outlined>
              <v-card-title>
                <span>Title Payment Details &nbsp;</span>
              </v-card-title>
              <v-container>
                <v-row wrap v-if="tableData1.length > 0">
                  <v-col cols="12" sm="6" md="3">
                    <label>
                      <h6>
                        <span class="text-danger">*</span> Paid Date / Charter
                        Date
                      </h6>
                    </label>
                    <v-menu
                      v-model="menu1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      lazy
                      transition="scale-transition"
                      offset-y
                      full-width
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          required
                          v-model="PaidDate"
                          label="Paid Date"
                          readonly
                          v-on="on"
                          background-color="#F3F6F9"
                          class="form-control form-control-lg form-control-solid"
                          validate-on-blur
                          solo
                          dense
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="PaidDate"
                        @input="menu1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <label>
                      <h6>
                        <span class="text-danger">*</span> Transaction Type
                      </h6>
                    </label>
                    <v-select
                      :reduce="(option) => option.value"
                      :items="TransactionTypeIdOptions"
                      :rules="TransactionTypeIdRules"
                      v-model="TransactionTypeId"
                      required
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="3" v-if="TransactionTypeId != ''">
                    <label>
                      <h6>
                        <span class="text-danger">*</span> Payment Id/Checque
                        No./DD No.
                      </h6>
                    </label>
                    <v-text-field
                      v-model="PaymentId"
                      :rules="PaymentIdRules"
                      hint="Enter the transaction reference no"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="3"
                    v-if="TransactionTypeId != 1 && TransactionTypeId != ''"
                  >
                    <label>
                      <h6>
                        <span class="text-danger">*</span> Transaction Fees
                      </h6>
                    </label>
                    <v-text-field
                      v-model="TransactionFees"
                      :rules="TransactionFeesRules"
                      min="0"
                      type="number"
                      hint="Enter the transaction fee amount"
                      dense
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col align="center" cols="12" sm="12" md="12">
                    <br />
                    <v-data-table
                      class="elevation-1"
                      loading-text="Loading... Please wait"
                      disable-sort
                      disable-filtering
                      v-model="selected"
                      :search="search"
                      :loading="TableLoadingFlag"
                      :item-key="tableOptions1.ItemKey"
                      :single-select="tableOptions1.SingleSelectFlag"
                      :headers="tableColumns1"
                      :items="tableData1"
                      :items-per-page="tableOptions1.ItemsPerPage"
                      :footer-props="tableOptions1.FooterProps"
                      :hide-default-footer="tableOptions1.HideDefaultFooter"
                    ></v-data-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>

            <br />
            <br />

            <v-card elevation="3" outlined>
              <v-card-title>
                <span>Subscription Payment Details &nbsp;</span>
              </v-card-title>
              <v-container>
                <v-row wrap v-if="tableData2.length > 0">
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label>
                      <h6><span class="text-danger">*</span> Year</h6>
                    </label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :items="YearCodeOptions"
                      :rules="YearCodeRules"
                      :loading="YearCodeOptionsLoading"
                      v-model="YearCode"
                      hint="Select Year"
                      dense
                      clearable
                      required
                      outlined
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <label>
                      <h6><span class="text-danger">*</span> Paid Date</h6>
                    </label>
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      lazy
                      transition="scale-transition"
                      offset-y
                      full-width
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          required
                          v-model="SubscriptionPaidDate"
                          label="Paid Date"
                          readonly
                          v-on="on"
                          background-color="#F3F6F9"
                          class="form-control form-control-lg form-control-solid"
                          validate-on-blur
                          solo
                          dense
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="SubscriptionPaidDate"
                        @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <label>
                      <h6>
                        <span class="text-danger">*</span> Transaction Type
                      </h6>
                    </label>
                    <v-select
                      :reduce="(option) => option.value"
                      :items="SubscriptionTransactionTypeIdOptions"
                      :rules="SubscriptionTransactionTypeIdRules"
                      v-model="SubscriptionTransactionTypeId"
                      required
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="3"
                    v-if="SubscriptionTransactionTypeId != ''"
                  >
                    <label>
                      <h6>
                        <span class="text-danger">*</span> Payment Id/Checque
                        No./DD No.
                      </h6>
                    </label>
                    <v-text-field
                      v-model="SubscriptionPaymentId"
                      :rules="SubscriptionPaymentIdRules"
                      hint="Enter the transaction reference no"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="3"
                    v-if="
                      SubscriptionTransactionTypeId != 1 &&
                      SubscriptionTransactionTypeId != ''
                    "
                  >
                    <label>
                      <h6>
                        <span class="text-danger">*</span> Transaction Fees
                      </h6>
                    </label>
                    <v-text-field
                      v-model="SubscriptionTransactionFees"
                      :rules="SubscriptionTransactionFeesRules"
                      min="0"
                      type="number"
                      hint="Enter the transaction fee amount"
                      dense
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col align="center" cols="12" sm="12" md="12">
                    <br />
                    <v-data-table
                      class="elevation-1"
                      loading-text="Loading... Please wait"
                      disable-sort
                      disable-filtering
                      v-model="selected"
                      :search="search"
                      :loading="TableLoadingFlag"
                      :item-key="tableOptions2.ItemKey"
                      :single-select="tableOptions2.SingleSelectFlag"
                      :headers="tableColumns2"
                      :items="tableData2"
                      :items-per-page="tableOptions2.ItemsPerPage"
                      :footer-props="tableOptions2.FooterProps"
                      :hide-default-footer="tableOptions2.HideDefaultFooter"
                    >
                      <template v-slot:item.FineCheckBox="{ item }">
                        <v-checkbox
                          v-if="item.FineFlag"
                          v-model="item.FinePaidFlag"
                          :label="item.FineAmountTxt"
                          color="info"
                          :value="item.FinePaidFlag"
                          hide-details
                          v-on="calculateTotal()"
                        ></v-checkbox>
                      </template>
                      <template v-slot:item.Qty="{ item }">
                        <v-text-field
                          v-model="item.Qty"
                          v-on="calculateTotal()"
                          type="number"
                          min="0"
                          :rules="QtyRules"
                        ></v-text-field>
                      </template>
                      <template v-slot:item.SubTotal="{ item }">{{
                        item.SubTotal
                      }}</template>
                    </v-data-table>
                  </v-col>
                </v-row>
                <br />
                <v-row>
                  <v-col align="center" cols="12" sm="12" md="12">
                    <h3 class="text-primary">
                      Total Amount : Rs.
                      {{ SubscriptionTotalAmount + TotalAmount }}
                    </h3>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>

            <br />
            <v-row wrap>
              <v-col align="center" cols="12" sm="12" md="12">
                <upload-progress
                  :ProgessStart="ProgessStart"
                  :UploadPercentage="uploadPercentage"
                ></upload-progress>
                <v-btn
                  :disabled="!valid1"
                  type="submit"
                  color="success"
                  class="mr-4"
                  @click.prevent="confirmAlert"
                  :loading="SubmitFlag"
                  >Pay now</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <br />
        <br />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";

export default {
  mixins: [common],
  components: {},
  data() {
    return {
      valid1: true,
      LoadingFlag: false,
      SubmitFlag: false,
      ProgressLoadingFlag: false,
      StartupLoadingFlag: false,
      selected: [],
      search: "",
      CurrentYearId: 0,

      ProgessStart: 0,
      uploadPercentage: 0,

      tableData1: [],
      tableColumns1: [],
      tableOptions1: [],
      tableData2: [],
      tableColumns2: [],
      tableOptions2: [],

      President: {},
      PresidentFlag: false,
      ZonePresident: {},
      ZonePresidentFlag: false,
      NewLoPresidentFlag: false,

      YearCodeRules: [(v) => !!v || "Year is required"],
      YearCode: "",
      YearCodeOptions: [],
      YearCodeOptionsLoading: false,

      SponsorType: "",
      SponsorTypeRules: [(v) => !!v || "Sponsor type is required"],
      SponsorTypeOptions: [
        { value: "", text: "--Select--" },
        { value: 1, text: "LO" },
        { value: 2, text: "Member" },
      ],

      SponsorMembershipId: "",
      SponsorMembershipIdLoading: false,
      SponsorMembershipIdRules: [
        (v) => !!v || "Sponsor Membership Id is required",
      ],
      SponsorMemberData: {},

      SponsorMobileNoRules: [(v) => !!v || "Mobile No is required"],

      SponsorEmailIdRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],

      NewLoPresidentType: "",
      NewLoPresidentTypeRules: [
        (v) => !!v || "New LO President type is required",
      ],
      NewLoPresidentTypeOptions: [
        { value: "", text: "--Select--" },
        { value: 1, text: "New Member" },
        { value: 2, text: "Existing Member" },
      ],

      NewLoPresidentMembershipId: "",
      NewLoPresidentMembershipIdLoading: false,
      NewLoPresidentMembershipIdRules: [
        (v) => !!v || "New Lo President Membership Id is required",
      ],
      NewLoPresidentData: {},

      SponsorZoneCodeRules: [(v) => !!v || "Zone is required"],
      SponsorZoneCode: "",
      SponsorZoneCodeOptions: [],
      SponsorZoneCodeOptionsLoading: false,

      SponsorLomCodeRules: [(v) => !!v || "LO is required"],
      SponsorLomCode: "",
      SponsorLomCodeOptions: [],
      SponsorLomCodeOptionsLoading: false,

      MobileNoRules: [(v) => !!v || "Mobile No is required"],

      EmailIdRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],

      QtyRules: [
        (v) => Number.isInteger(Number(v)) || "Nos is invalid",
        (v) => v >= 0 || "Number must be greater than zero",
      ],

      LomName: "",
      LomNameRules: [(v) => !!v || "LO name is required"],

      StateCodeRules: [(v) => !!v || "State is required"],
      StateCode: "",
      StateCodeOptions: [],
      StateCodeOptionsLoading: false,

      DistrictCodeRules: [(v) => !!v || "District is required"],
      DistrictCode: "",
      DistrictCodeOptions: [],
      DistrictCodeOptionsLoading: false,

      CityCodeRules: [(v) => !!v || "City is required"],
      CityCode: "",
      CityCodeOptions: [],
      CityCodeOptionsLoading: false,

      LGBResolution: {},
      OrientationCertificate: {},
      MembershipList: {},

      FirstName: "",
      FirstNameRules: [(v) => !!v || "Fisrt name is required"],

      MiddleName: "",
      MiddleNameRules: [],

      LastName: "",
      LastNameRules: [],

      Street1: "",
      Street1Rules: [(v) => !!v || "Street 1 is required"],

      Street2: "",
      Street2Rules: [],

      AreaName: "",
      AreaNameRules: [],

      Pincode: "",
      PincodeRules: [(v) => !!v || "Mobile No is required"],

      PresidentMobileNo: "",
      PresidentMobileNoRules: [(v) => !!v || "Mobile No is required"],

      PresidentEmailId: "",
      PresidentEmailIdRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],

      PresidentStateCodeRules: [(v) => !!v || "State is required"],
      PresidentStateCode: "",
      PresidentStateCodeOptions: [],
      PresidentStateCodeOptionsLoading: false,

      PresidentDistrictCodeRules: [(v) => !!v || "District is required"],
      PresidentDistrictCode: "",
      PresidentDistrictCodeOptions: [],
      PresidentDistrictCodeOptionsLoading: false,

      PresidentCityCodeRules: [(v) => !!v || "City is required"],
      PresidentCityCode: "",
      PresidentCityCodeOptions: [],
      PresidentCityCodeOptionsLoading: false,

      PaidDate: "",
      PaidDateRules: [(v) => !!v || "From date is required"],
      menu1: false,

      TransactionTypeId: "",
      TransactionTypeIdRules: [
        (v) => !!v || "Transaction type should not be empty",
      ],
      TransactionTypeIdOptions: [],
      TransactionTypeIdOptionsLoading: false,

      PaymentId: "",
      PaymentIdRules: [(v) => !!v || "Payment Id is required"],

      TransactionFees: 0,
      TransactionFeesRules: [],

      SubscriptionPaidDate: "",
      SubscriptionPaidDateRules: [(v) => !!v || "From date is required"],
      menu2: false,

      SubscriptionTransactionTypeId: "",
      SubscriptionTransactionTypeIdRules: [
        (v) => !!v || "Transaction type should not be empty",
      ],
      SubscriptionTransactionTypeIdOptions: [],
      SubscriptionTransactionTypeIdOptionsLoading: false,

      SubscriptionPaymentId: "",
      SubscriptionPaymentIdRules: [(v) => !!v || "Payment Id is required"],

      SubscriptionTransactionFees: 0,
      SubscriptionTransactionFeesRules: [],

      SubscriptionTotalAmount: 0,

      CartDetails: {},

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
      FloatingButtonOptions: {},
    };
  },
  mounted() {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }

        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;
        this.FloatingButtonOptions = tr.FloatingButtonOptions;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;
        this.YearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;

        this.pageData();
      }
    },
    SponsorType: function () {
      console.log("watch SponsorType");
      var SponsorType = this.SponsorType;
      console.log({ SponsorType });

      var ZonePresidentName = this.ZonePresident.MemberName;
      ZonePresidentName =
        ZonePresidentName == (null || undefined) ? "" : ZonePresidentName;
      console.log({ ZonePresidentName });

      var ZonePresidentFlag =
        SponsorType == 1 && ZonePresidentName != "" ? true : false;
      console.log({ ZonePresidentFlag });
      this.ZonePresidentFlag = ZonePresidentFlag;

      var LoPresidentName = this.President.MemberName;
      LoPresidentName =
        LoPresidentName == (null || undefined) ? "" : LoPresidentName;
      console.log({ LoPresidentName });

      var PresidentFlag =
        SponsorType == 1 && LoPresidentName != "" && ZonePresidentFlag
          ? true
          : false;
      console.log({ PresidentFlag });
      this.PresidentFlag = PresidentFlag;

      this.PresidentFlag = PresidentFlag;
      this.SponsorMembershipId = "";
      this.SponsorMemberData = {};
    },
    NewLoPresidentType: function () {
      console.log("watch NewLoPresidentType");
      var NewLoPresidentType = this.NewLoPresidentType;
      console.log({ NewLoPresidentType });
      var NewLoPresidentFlag = NewLoPresidentType == 1 ? true : false;
      console.log({ NewLoPresidentFlag });
      this.NewLoPresidentFlag = NewLoPresidentFlag;
      this.NewLoPresidentMembershipId = "";
      this.NewLoPresidentData = {};
    },
    CartDetails: function () {
      console.log("watch CartDetails");
      this.TableLoadingFlag = false;
      var tr = this.CartDetails;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var tableOptions = tr.TableOptions;
        this.tableOptions1 = tableOptions;
        this.tableColumns1 = tr.TableHeader;
        this.tableData1 = tr.TableData;
        this.TotalAmount = tableOptions.TotalAmount;
      }
    },
    SponsorZoneCodeOptions: function () {
      console.log("watch SponsorZoneCodeOptions");
      this.StartupLoadingFlag = false;
      this.SponsorZoneCodeOptionsLoading = false;
    },
    SponsorZoneCode: function () {
      console.log("watch SponsorZoneCode");
      this.getZonePresidentDetails();
      this.getLomCodeOptions();
    },
    SponsorLomCodeOptions: function () {
      console.log("watch SponsorLomCodeOptions");
      this.LoadingFlag = false;
      this.SponsorLomCodeOptionsLoading = false;
    },
    SponsorLomCode: function () {
      console.log("watch SponsorLomCode");
      this.getPresidentDetails();
    },
    StateCodeOptions: function () {
      console.log("watch StateCodeOptions");
      this.StateCodeOptionsLoading = false;
    },
    StateCode: function () {
      console.log("watch StateCode");
      this.DistrictCodeOptionsLoading = true;
      this.getDistrictCodeOptions(
        "DistrictCode",
        "DistrictCodeOptions",
        this.StateCode
      );
    },
    DistrictCodeOptions: function () {
      console.log("watch DistrictCodeOptions");
      this.DistrictCodeOptionsLoading = false;
    },
    DistrictCode: function () {
      console.log("watch DistrictCode");
      this.CityCodeOptionsLoading = true;
      this.getCityCodeOptions("CityCode", "CityCodeOptions", this.DistrictCode);
    },
    CityCodeOptions: function () {
      console.log("watch CityCodeOptions");
      this.CityCodeOptionsLoading = false;
    },
    PresidentStateCodeOptions: function () {
      console.log("watch PresidentStateCodeOptions");
      this.PresidentStateCodeOptionsLoading = false;
    },
    PresidentStateCode: function () {
      console.log("watch PresidentStateCode");
      this.PresidentDistrictCodeOptionsLoading = true;
      this.getDistrictCodeOptions(
        "PresidentDistrictCode",
        "PresidentDistrictCodeOptions",
        this.PresidentStateCode
      );
    },
    PresidentDistrictCodeOptions: function () {
      console.log("watch PresidentDistrictCodeOptions");
      this.PresidentDistrictCodeOptionsLoading = false;
    },
    PresidentDistrictCode: function () {
      console.log("watch PresidentDistrictCode");
      this.PresidentCityCodeOptionsLoading = true;
      this.getCityCodeOptions(
        "PresidentCityCode",
        "PresidentCityCodeOptions",
        this.PresidentDistrictCode
      );
    },
    PresidentCityCodeOptions: function () {
      console.log("watch PresidentCityCodeOptions");
      this.PresidentCityCodeOptionsLoading = false;
    },
    TransactionTypeIdOptions: function () {
      console.log("watch TransactionTypeIdOptions");
      this.TransactionTypeIdOptionsLoading = false;
    },
    SubscriptionTransactionTypeIdOptions: function () {
      console.log("watch SubscriptionTransactionTypeIdOptions");
      this.SubscriptionTransactionTypeIdOptionsLoading = false;
    },
    YearCodeOptions: function () {
      console.log("watch YearCodeOptions");
      this.LoadingFlag = false;
      this.YearCodeOptionsLoading = false;
    },
    YearCode: function () {
      console.log("watch YearCode");
      var YearCode = this.YearCode;
      console.log({ YearCode });
      this.getRegularSubscriptionList();
    },
  },
  methods: {
    // code 1
    backPage() {
      this.$router.go(-1);
    },
    resetForm() {
      this.$refs.form2.reset();
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      this.StartupLoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "jci_india_lom",
        Action: "create",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);

      this.TableLoadingFlag = true;
      var url2 = "api/new-lom/cart-details";
      var condition2 = {
        UserInterface: 1,
      };
      this.getPageDetails("CartDetails", {}, url2, condition2);
    },
    pageData() {
      console.log("pageData called");
      this.SponsorZoneCodeOptionsLoading = true;
      this.getZoneCodeOptions();
      this.getJciYearCodeOptions();

      this.StateCodeOptionsLoading = true;
      this.getStateCodeOptions("StateCode", "StateCodeOptions");
      this.getStateCodeOptions(
        "PresidentStateCode",
        "PresidentStateCodeOptions"
      );

      this.PresidentStateCodeOptionsLoading = true;
      this.getTransactionTypeIdOptions(
        "TransactionTypeId",
        "TransactionTypeIdOptions"
      );

      this.SubscriptionTransactionTypeIdOptionsLoading = true;
      this.getTransactionTypeIdOptions(
        "SubscriptionTransactionTypeId",
        "SubscriptionTransactionTypeIdOptions"
      );
      // this.getRegularSubscriptionList();
    },
    getJciYearCodeOptions() {
      this.LoadingFlag = true;
      this.YearCodeOptionsLoading = true;
      console.log("getYearCodeOptions called");
      var selectbox1_source = "YearCode";
      var selectbox1_destination = "YearCodeOptions";
      var selectbox1_url = "api/jci-year/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getZoneCodeOptions() {
      this.StartupLoadingFlag = true;
      console.log("getZoneCodeOptions called");
      var yearCode = this.JciYearCode;
      if (yearCode != "") {
        var selectbox1_source = "SponsorZoneCode";
        var selectbox1_destination = "SponsorZoneCodeOptions";
        var selectbox1_url = "api/year-wise-lom/zone-president-options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          YearCode: yearCode,
          NotAvailable: 0,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (yearCode == "") {
          message += "Year should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    getLomCodeOptions() {
      this.LoadingFlag = true;
      this.SponsorLomCodeOptionsLoading = true;
      console.log("getLomCodeOptions called");
      var yearCode = this.JciYearCode;
      var SponsorZoneCode = this.SponsorZoneCode;
      console.log(
        "SponsorZoneCode=" + SponsorZoneCode + ", yearCode=" + yearCode
      );
      if (SponsorZoneCode != "" && yearCode != "") {
        var selectbox1_source = "SponsorLomCode";
        var selectbox1_destination = "SponsorLomCodeOptions";
        var selectbox1_url = "api/year-wise-lom/lom-options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          YearCode: yearCode,
          ZoneCode: SponsorZoneCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (yearCode == "") {
          message += "Year should not be empty. ";
        }
        if (SponsorZoneCode == "") {
          message += "Sponsor Zone should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    getStateCodeOptions(source, destination) {
      console.log("getStateCodeOptions called");
      if (source != "" && destination != "") {
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/states/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getDistrictCodeOptions(source, destination, StateCode) {
      console.log("getDistrictCodeOptions called StateCode=" + StateCode);
      if (source != "" && destination != "" && StateCode != "") {
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/district/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          StatesCode: StateCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getCityCodeOptions(source, destination, DistrictCode) {
      console.log("getCityCodeOptions called DistrictCode=" + DistrictCode);
      if (source != "" && destination != "" && DistrictCode != "") {
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/city/options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          DistrictCode: DistrictCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getPresidentDetails() {
      console.log("getPresidentDetails called");
      // this.resetSubscription();
      var temp_code = this.SponsorLomCode;
      console.log("temp_code=" + temp_code);
      var idx = this.SponsorLomCodeOptions.map((e) => e.value).indexOf(
        temp_code
      );
      console.log("idx=" + idx);
      if (idx >= 0) {
        var President = this.SponsorLomCodeOptions[idx];
        console.log("President=" + JSON.stringify(President));
        this.President = President;
        this.PresidentFlag = President.MemberName != "" ? true : false;
      }
    },
    getZonePresidentDetails() {
      console.log("getZonePresidentDetails called");
      // this.resetSubscription();
      var temp_code = this.SponsorZoneCode;
      console.log("temp_code=" + temp_code);
      var idx = this.SponsorZoneCodeOptions.map((e) => e.value).indexOf(
        temp_code
      );
      console.log("idx=" + idx);
      if (idx >= 0) {
        var ZonePresident = this.SponsorZoneCodeOptions[idx];
        console.log("ZonePresident=" + JSON.stringify(ZonePresident));
        this.ZonePresident = ZonePresident;
        this.ZonePresidentFlag = ZonePresident.MemberName != "" ? true : false;
        this.President = {};
        this.PresidentFlag = false;
      }
    },
    getTransactionTypeIdOptions(source, destination) {
      console.log("getTransactionTypeIdOptions called");
      if (source != "" && destination != "") {
        this.TransactionTypeIdOptionsLoading = true;
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/sub-category/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          CategoryMainId: 3,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getMemberDetails(MemberTypeId) {
      console.log("getMemberDetails is called");

      console.log({ MemberTypeId });

      var MembershipId =
        MemberTypeId == 1
          ? this.SponsorMembershipId
          : this.NewLoPresidentMembershipId;
      console.log("MembershipId=" + MembershipId);

      if (MembershipId != "") {
        if (MemberTypeId == 1) {
          this.SponsorMembershipIdLoading = true;
          this.SponsorMemberData = {};
        } else {
          this.NewLoPresidentMembershipIdLoading = true;
          this.NewLoPresidentData = {};
        }

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/members/search";
        var upload = {
          UserInterface: 5,
          MembershipId: MembershipId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            if (MemberTypeId == 1) {
              thisIns.SponsorMembershipIdLoading = false;
            } else {
              thisIns.NewLoPresidentMembershipIdLoading = false;
            }

            output = response.data.output;
            flag = response.data.flag;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            console.log({ records });

            if (flag == 1) {
              if (MemberTypeId == 1) {
                thisIns.SponsorMemberData = records;
              } else {
                thisIns.NewLoPresidentData = records;
              }
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.NewLoPresidentMembershipIdLoading = false;
            thisIns.SponsorMembershipIdLoading = false;
          });
      } else {
        var message = "";
        if (MembershipId == "") {
          message += "Membership Id should not be empty.";
        }
        this.toast("error", message);
      }
    },
    getRegularSubscriptionList() {
      console.log("getRegularSubscriptionList is called");

      var JciYearCode = this.YearCode;
      console.log("JciYearCode=" + JciYearCode);

      // JciYearCode = "";

      if (JciYearCode != "") {
        this.LoadingFlag = true;
        this.tableData2 = [];

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/year-wise-contribution/lists";
        var upload = {
          UserInterface: 3,
          YearCode: JciYearCode,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            thisIns.tableOptions2 = records.TableOptions;

            if (flag == 1) {
              thisIns.tableColumns2 = records.TableHeader;
              thisIns.tableData2 = records.TableData;
              // thisIns.toast("success", output);
              // thisIns.resetForm();
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            this.SearchFlag = false;
          });
      } else {
        var message = "";
        if (JciYearCode == "") {
          message += "Year should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    calculateTotal() {
      console.log("calculateTotal called");
      var n1 = this.tableData2.length;
      var totalAmount = 0;
      // console.log("n1=" + n1 + ", tableData2=" + JSON.stringify(this.tableData2));
      for (var i = 0; i < n1; i++) {
        var qty = this.tableData2[i]["Qty"];
        var PenaltyAmount = this.tableData2[i]["FineAmount"];
        var WithoutFineAmount = this.tableData2[i]["WithoutFineAmount"];
        var FinePaidFlag = this.tableData2[i]["FinePaidFlag"];
        var amount = this.tableData2[i]["Amount"];
        console.log(
          "i=" +
            i +
            ", qty=" +
            qty +
            ", PenaltyAmount=" +
            PenaltyAmount +
            ", WithoutFineAmount=" +
            WithoutFineAmount +
            ", FinePaidFlag=" +
            FinePaidFlag +
            ", amount=" +
            amount
        );
        amount = FinePaidFlag ? amount : WithoutFineAmount;
        PenaltyAmount = FinePaidFlag ? PenaltyAmount : 0;
        console.log("amount=" + amount + ", PenaltyAmount=" + PenaltyAmount);
        qty = !isNaN(qty) ? (qty >= 0 ? qty : 0) : 0;
        amount = !isNaN(amount) ? amount : 0;
        console.log("qty=" + qty + ", amount=" + amount);
        var subTotal = parseFloat(qty) * parseFloat(amount);
        this.tableData2[i]["PenaltyAmount"] = PenaltyAmount;
        this.tableData2[i]["PenaltyAmountTxt"] = this.getDecimalNumber(
          PenaltyAmount,
          2
        );
        this.tableData2[i]["SubTotal"] = isNaN(subTotal)
          ? 0
          : this.getDecimalNumber(subTotal, 2);
      }
      var totalAmount = this.tableData2.reduce(function (sum, line) {
        var lineTotal = parseFloat(line.SubTotal);
        console.log("lineTotal=" + lineTotal);
        if (!isNaN(lineTotal)) {
          return sum + lineTotal;
        }
      }, 0);
      console.log("totalAmount=" + totalAmount);
      this.SubscriptionTotalAmount = isNaN(totalAmount)
        ? 0
        : this.getDecimalNumber(totalAmount, 2);
    },
    confirmAlert() {
      var validate1 = this.$refs.form2.validate();
      console.log({ validate1 });

      var SubscriptionTotalAmount = this.SubscriptionTotalAmount;
      console.log({ SubscriptionTotalAmount });

      var TotalAmount = this.TotalAmount;
      console.log({ TotalAmount });

      var President = this.President;
      console.log({ President });

      var PresidentMemberId = President.MemberId;
      console.log({ PresidentMemberId });

      var ZonePresident = this.ZonePresident;
      console.log({ ZonePresident });

      var ZonePresidentMemberId = ZonePresident.PresidentMemberId;
      console.log({ ZonePresidentMemberId });

      var PaidDate = this.PaidDate;
      console.log({ PaidDate });
      var SubscriptionPaidDate = this.SubscriptionPaidDate;
      console.log({ SubscriptionPaidDate });

      // TotalAmount = 0;

      if (
        validate1 &&
        SubscriptionTotalAmount > 0 &&
        TotalAmount > 0 &&
        PresidentMemberId != "" &&
        ZonePresidentMemberId != "" &&
        PaidDate != "" &&
        SubscriptionPaidDate != ""
      ) {
        var htmlTxt = "";
        // htmlTxt += "<b>Please verify all the information before proceeding</b>";
        htmlTxt += "<b>Selected New LO details</b>";
        htmlTxt += "<br/><br/>";
        htmlTxt += "New LO Name <b>" + this.LomName + "</b><br/>";
        htmlTxt += "Title Payment Amount. <b>Rs. " + TotalAmount + "</b><br/>";
        htmlTxt +=
          "Subscription Payment Amount. <b>Rs. " +
          SubscriptionTotalAmount +
          "</b><br/>";
        htmlTxt += "<br/><br/>";
        htmlTxt += "Do you want to continue?";
        Swal.fire({
          title: "Please verify all the information before proceeding",
          text: "",
          icon: "warning",
          html: htmlTxt,
          showCancelButton: true,
          confirmButtonText: `Continue`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.submitForm();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields. ";
        }
        if (TotalAmount == 0) {
          message += "Title Payment details invalid. ";
        }
        if (SubscriptionTotalAmount == 0) {
          message += "Subscription Payment details invalid. ";
        }
        if (PresidentMemberId == 0 || PresidentMemberId == "") {
          message += "President details invaid. ";
        }
        if (ZonePresidentMemberId == 0 || ZonePresidentMemberId == "") {
          message += "Zone President details invaid. ";
        }
        if (PaidDate == "") {
          message += "Title Paid Date / Charter Date should not be empty. ";
        }
        if (SubscriptionPaidDate == "") {
          message += "Subscription Paid Date should not be empty. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form2.validate();

      if (validate1) {
        this.SubmitFlag = true;
        this.ProgressLoadingFlag = true;

        var President = this.President;
        console.log({ President });

        var PresidentMemberId = President.MemberId;
        console.log({ PresidentMemberId });

        var ZonePresident = this.ZonePresident;
        console.log({ ZonePresident });

        var ZonePresidentMemberId = ZonePresident.PresidentMemberId;
        console.log({ ZonePresidentMemberId });

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/lom/store";
        var uploadData = {
          SponsorType: this.SponsorType,
          SponsorMembershipId: this.SponsorMembershipId,
          NewLoPresidentType: this.NewLoPresidentType,
          NewLoPresidentMembershipId: this.NewLoPresidentMembershipId,
          SponsorMemberData: this.SponsorMemberData,
          NewLoPresidentData: this.NewLoPresidentData,
          Lom: this.SponsorLomCode,
          Member: PresidentMemberId,
          ZonePresident: ZonePresidentMemberId,
          MobileNo: President.PrimaryMobile,
          EmailId: President.EmailId,
          NewLomName: this.LomName,
          City: this.CityCode,
          PaidDate: this.PaidDate,
          TransactionTypeId: this.TransactionTypeId,
          PaymentId: this.PaymentId,
          TransactionFees: this.TransactionFees,
          TotalAmount: this.TotalAmount,
          FirstName: this.FirstName,
          MiddleName: this.MiddleName,
          LastName: this.LastName,
          NewPresidentMobileNo: this.PresidentMobileNo,
          NewPresidentEmailId: this.PresidentEmailId,
          Street1: this.Street1,
          Street2: this.Street2,
          AreaName: this.AreaName,
          Pincode: this.Pincode,
          NewPresidentCity: this.PresidentCityCode,
          Year: this.YearCode,
          SubscriptionPaidDate: this.SubscriptionPaidDate,
          SubscriptionTransactionTypeId: this.SubscriptionTransactionTypeId,
          SubscriptionPaymentId: this.SubscriptionPaymentId,
          SubscriptionTransactionFees: this.SubscriptionTransactionFees,
          SubscriptionTotalAmount: this.SubscriptionTotalAmount,
          Contribution: this.tableData2,
        };

        let upload = new FormData();
        upload.append("Form", JSON.stringify(uploadData));
        upload.append("UserInterface", 1);
        upload.append("OrientationCertificate", this.OrientationCertificate);
        upload.append("MembershipList", this.MembershipList);
        upload.append("LGBResolution", this.LGBResolution);

        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.SubmitFlag = false;
            thisIns.ProgressLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            records = response.data.records;
            console.log({ records });

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.pageRedirect({}, "/lom/list");
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
            thisIns.ProgressLoadingFlag = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields. ";
        }
        this.toast("error", message);
      }
    },
  },
  beforeMount() {
    var CurrentYearId = this.$session.get("CurrentYearId");
    CurrentYearId = 1;
    CurrentYearId = CurrentYearId == (null || undefined) ? 0 : CurrentYearId;
    this.CurrentYearId = CurrentYearId;
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#manual-lo-create {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
}
</style>